//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.blur {
	animation-name: imgLoad;
	animation-duration: .5s;
	animation-delay: .5s;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
}


.no-blur {
	filter: blur(0);
	transition: filter .5s 2s ease-in;
}
@keyframes imgLoad {
	from {opacity: 0;}
	to {opacity: 1;}
}

.video-container {
	position: relative;
	padding-bottom: 56.25%;
}

.video-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.ql-editor{
	min-height:100px !important;
}